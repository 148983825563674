import React, { useState, useEffect, useRef } from "react";
import { HeadWrapper } from "../Elements";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { socketUrl } from "../../constant/BaseUrl";
import socketIOClient from "socket.io-client";
import { BuyOrdersTable } from "./BuyOrderTable";
import { SellOrdersTable } from "./SellOrderTable";
import { useDeleteOrderMutation } from "../../../redux/exchange";
import { handleToast } from "../../utils/handleToast";
import { BuyOrdersTableMargin } from "./BuyOrdersTableMargin";
import { SellOrdersTableMargin } from "./SellOrdersTableMargin";

const LiveOrders = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [buyOrders, setBuyOrders] = useState([]);
  const [sellOrders, setSellOrders] = useState([]);
  const [tabIndexMargin, setTabIndexMargin] = useState(0);
  const [buyOrdersMargin, setBuyOrdersMargin] = useState([]);
  const [sellOrdersMargin, setSellOrdersMargin] = useState([]);
  const [orderTypeSelect, setOrderTypeSelect] = useState("Margin");
  const localSocket = useRef(null);
  const [deleteOrder] = useDeleteOrderMutation();

  useEffect(() => {
    if (!localSocket.current) {
      localSocket.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("jwtToken"),
        },
      });
      localSocket.current.on("connect", () => {
        console.log("Socket is connected");
        handleSocketMessages(localSocket);
      });
      localSocket.current.emit("ping");
      localSocket.current.emit("getAdminOrders");
      localSocket.current.emit("getAdminOrdersMargin");
    }
    return () => {
      if (
        localSocket.current &&
        !window.location.pathname.includes("/live-orders")
      ) {
        console.log("WebSocket connection will be closed on unmount.");
        localSocket.current.disconnect();
      }
    };
  }, []);

  const handleSocketMessages = (localSocket) => {
    localSocket.current.on("pong", () => {
      console.log("Received PONG from server");
    });

    localSocket.current.on(
      "resultAdminOrdersMargin",
      ({ buyOrderData, sellOrderData }) => {
        console.log("buyOrderData.data", buyOrderData.data);
        setBuyOrdersMargin(buyOrderData.data);
        setSellOrdersMargin(sellOrderData.data);
      }
    );

    localSocket.current.on(
      "resultAdminOrders",
      ({ buyOrderData, sellOrderData }) => {
        console.log("normal   buyOrderData.data", buyOrderData.data);

        setBuyOrders(buyOrderData.data);
        setSellOrders(sellOrderData.data);
      }
    );

    localSocket.current.off("refresh");
    localSocket.current.on("refresh", () => {
      localSocket.current.emit("ping");
      localSocket.current.emit("getAdminOrdersMargin");
      localSocket.current.emit("getAdminOrders");
      handleSocketMessages(localSocket);
    });
  };

  const handleTabs = (index) => {
    setTabIndex(index);
  };

  const handleTabsMargin = (index) => {
    setTabIndexMargin(index);
  };

  const handleOrderType = (value) => {
    setOrderTypeSelect(value);
  };

  const handleAdminDeleteOrder = async (data) => {
    const { userId, orderType, orderId } = data;

    try {
      const mutationResult = await deleteOrder({ userId, orderType, orderId });
      handleToast(mutationResult);
    } catch (error) {}
  };

  return (
    <HeadWrapper title="Live Orders">
      <div className="mb-3">
        <button
          className={`btn btn-secondary me-3 ${
            orderTypeSelect === "Spot" ? "active" : ""
          }`}
          onClick={() => handleOrderType("Spot")}
        >
          Live Spot Orders
        </button>
        <button
          className={`btn btn-secondary ${
            orderTypeSelect === "Margin" ? "active" : ""
          }`}
          onClick={() => handleOrderType("Margin")}
        >
          Live Margin Orders
        </button>
      </div>

      <div className="white-box">
        {orderTypeSelect === "Margin" && (
          <Tabs selectedIndex={tabIndexMargin} onSelect={handleTabsMargin}>
            <TabList>
              <Tab>BUY</Tab>
              <Tab>SELL</Tab>
            </TabList>

            <TabPanel>
              <BuyOrdersTableMargin
                data={buyOrdersMargin}
                onDelete={handleAdminDeleteOrder}
              />
            </TabPanel>
            <TabPanel>
              <SellOrdersTableMargin
                data={sellOrdersMargin}
                onDelete={handleAdminDeleteOrder}
              />
            </TabPanel>
          </Tabs>
        )}

        {orderTypeSelect === "Spot" && (
          <Tabs selectedIndex={tabIndex} onSelect={handleTabs}>
            <TabList>
              <Tab>BUY</Tab>
              <Tab>SELL</Tab>
            </TabList>

            <TabPanel>
              <BuyOrdersTable
                data={buyOrders}
                onDelete={handleAdminDeleteOrder}
              />
            </TabPanel>
            <TabPanel>
              <SellOrdersTable
                data={sellOrders}
                onDelete={handleAdminDeleteOrder}
              />
            </TabPanel>
          </Tabs>
        )}
      </div>
    </HeadWrapper>
  );
};

export default LiveOrders;
