import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useGetCryptoPairsQuery } from "../../../redux/cryptoPairsApi";
import TableLoader from "../../widgets/TableLoader";
import toast from "react-hot-toast";
import {
  useGetLeveragesPermissionsQuery,
  useLeveragePermissionMutation,
} from "../../../redux/leverageApi";
import Button from "../../widgets/Button";

export const Leverage = ({ editData }) => {
  const [selectedPairs, setSelectedPairs] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [currantPairs, setCurrantPairs] = useState([]);
  const {
    data: pairsData,
    isLoading: pairsLoading,
    refetch: refetchPairs,
  } = useGetLeveragesPermissionsQuery({ userId: editData.id });
  const { data: records, isLoading } = useGetCryptoPairsQuery();
  const [updateLeveragePermissions, { isLoading: updatingAssets }] =
    useLeveragePermissionMutation();

  useEffect(() => {
    const createKeyValuePair = (leverageAmounts) => {
      return leverageAmounts?.map((lev) => ({
        value: lev?.id,
        label: `${lev.amount} (${lev.type})`,
      }));
    };
    if (pairsData && editData) {
      const list = [
        ...pairsData.parentPermissions,
        ...pairsData.ownPermissions,
      ];
      const pairValues = createKeyValuePair(list);
      const ownPermissionsValues = createKeyValuePair(pairsData.ownPermissions);

      setSelectedOptions(ownPermissionsValues);
      setCurrantPairs(pairValues);
      // setSelectedPairs(pairValues);
      setIsShow(true);
    }
  }, [editData, pairsData]);

  // const selectedOptions = useMemo(() => {
  //   return currantPairs?.map((pair) => ({
  //     value: pair.pair_id,
  //     label: `${pair.name1?.toUpperCase()}/${pair.name2?.toUpperCase()}`,
  //   }));
  // }, [currantPairs]);

  const handlePermissionsTabsSubmit = async () => {
    console.log(selectedPairs, "selectedPairssubbb");
    // const values = selectedPairs.map((coinId) => coinId.value);

    const result = await updateLeveragePermissions({
      leverageIds: selectedPairs.value,
      userId: editData.id,
    });
    toast.dismiss();
    if (result?.data?.status) {
      toast.success(result.data.message);
      refetchPairs();
    } else {
      toast.error(result?.data?.message || "Error occurred");
    }
  };

  if (!pairsData && !records) {
    return <TableLoader />;
  }
  return (
    <>
      {isLoading && pairsLoading ? (
        <TableLoader />
      ) : (
        <div>
          <h4>Select Leverage</h4>
          {!isShow ? (
            <TableLoader />
          ) : (
            <>
              <Select
                defaultValue={selectedOptions}
                // isMulti
                name="colors"
                options={currantPairs}
                className="basic-multi-select"
                classNamePrefix="select"
                isLoading={isLoading}
                onChange={(selected) => {
                  setSelectedPairs(selected);
                }}
              />

              <Button
                className="btn btn1 mt-2"
                onClick={handlePermissionsTabsSubmit}
                isLoading={updatingAssets}
              >
                Save
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};
