import React from "react";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import { handleToast } from "../../utils/handleToast";
import { useUserDeleteMutation } from "../../../redux/usersApi";

export const UserDelete = ({ id, onClose, show, refetch, status }) => {
  const [userDelete, { isLoading }] = useUserDeleteMutation();
  const handleDelete = async () => {
    const result = await userDelete({ id });

    handleToast(result);
    if (result?.data?.status) {
      refetch();
      onClose();
    }
  };
  return (
    <CustomModal
      isOpen={show}
      onClose={onClose}
      title={"Delete User"}
      footer={
        <Button
          className="btn "
          variant={"danger"}
          isLoading={isLoading}
          onClick={handleDelete}
        >
          Delete
        </Button>
      }
    >
      <div>Are you sure you want to update Delete?</div>
    </CustomModal>
  );
};
