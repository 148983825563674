import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { formatDate } from "../../utils/format";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const SellOrdersTableEq = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "S. No.",
        enableEditing: false,
        size: 40,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        accessorKey: "user_name",
        header: "Username",
        size: 50,
      },
      {
        accessorKey: "email",
        header: "Email",
        Cell: ({ row: { original } }) => <span>{original.email || "-"}</span>,
      },
      {
        accessorKey: "mobile_number",
        header: "Mobile No.",
        Cell: ({ row: { original } }) => (
          <span>{original.mobile_number || "-"}</span>
        ),
      },
      {
        accessorKey: "symbol",
        header: "Symbol",
        // Cell: ({ row: { original } }) => (
        //   <span>{`${original.first_coin_symbol}/${original.second_coin_symbol}`}</span>
        // ),
      },
      {
        accessorKey: "quantity",
        header: "Volume",
        Cell: ({ row: { original } }) => (
          <span>{original.sell_quantity || "-"}</span>
        ),
      },
      // {
      //   accessorKey: "updated_at",
      //   header: "Quantity",
      //   Cell: ({ row }) => <span>{formatDate(row.original.updated_at)}</span>,
      // },
      {
        // accessorKey: "refer_id",
        header: "Price Change",
        Cell: ({ row }) => (
          <>
            {Number(row.original.price)}
            <ArrowRightAltIcon />
            {Number(row.original.closed_price)}{" "}
          </>
        ),
      },

      {
        // accessorKey: "refer_id",
        header: "Profit and loss",
        Cell: ({ row }) => {
          const currentPricea = row.original.closed_price;
          const orderPrice = row.original.price;
          const priceChange = orderPrice - currentPricea;
          const qty =
            row.original.order_side === "SELL"
              ? row.original.buy_quantity
              : row.original.sell_quantity;
          const quantity = parseFloat(qty);

          const floatingProfit = priceChange * quantity;
          return (
            <>
              {/* {Number(row.original.price)} */}
              {floatingProfit}
            </>
          );
        },
      },

      // {
      //   accessorKey: "buy_fees",
      //   header: "Buy Fees",
      // },

      // {
      //   accessorKey: "orderMethod",
      //   header: "Order Type",
      // },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ row: { original } }) => <span>{original.status}</span>,
      },

      {
        accessorKey: "createdAt",
        header: "Date",
        enableEditing: false,
        Cell: ({ row }) => <span>{formatDate(row.original.updated_at)}</span>,
      },
    ],
    [data]
  );

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: data ? data : [],
    // enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableEditing: false,
    getRowId: (row) => row.id,

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};
