import React, { useState } from "react";
import TableLoader from "../../widgets/TableLoader";
import CurrencyTable from "./CurrencyTable";
import {
  useGetFxPairsQuery,
  useUpdateFxCoinMutation,
} from "../../../redux/fxApi";
import CustomModal from "../../widgets/Modal";
import Button from "../../widgets/Button";
import { ImageValid } from "../../validations/ImageValid";
import { createFormDataFromObject } from "../../utils/formData";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import { handleToast } from "../../utils/handleToast";
import { baseUrl } from "../../constant/BaseUrl";
const url = `${baseUrl.replace("/api-admin/v1", "")}`;
const defaultCryptoState = {
  symbol: { value: "", required: true },
  currencyImage: { value: "", required: true },
  companyName: { value: "", required: true },
};
const defaultCryptoError = {
  currencyImage: "",
  companyName: "",
};

const Currency = () => {
  const [updateFxCoin, { isLoading: updatingCrypto }] =
    useUpdateFxCoinMutation();
  const { data, isLoading, refetch } = useGetFxPairsQuery();
  const [isEdit, setIsEdit] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [show, setShow] = useState(false);
  const [symbolFx, setSymbolFx] = useState(defaultCryptoState);
  const [symbolFxError, setSymbolFxError] = useState(defaultCryptoError);

  const [editData, setEditData] = useState(null);

  const handleClose = () => {
    setShow(false);
    setImagePreview(null);
    setEditData(null);
    setSymbolFx(defaultCryptoState);
    setSymbolFxError(defaultCryptoError);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const error = ImageValid(file);

    if (error) {
      setSymbolFxError((prevError) => ({
        ...prevError,
        currencyImage: error,
      }));
      return;
    }

    setSymbolFx((prevCrypto) => ({
      ...prevCrypto,
      currencyImage: { value: file, required: true },
    }));

    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setSymbolFxError((prevError) => ({
        ...prevError,
        currencyImage: "",
      }));
    }
  };

  const handleChangeCompany = (e) => {
    const value = e.target.value;

    setSymbolFx((prevCrypto) => ({
      ...prevCrypto,
      companyName: { value: value, required: true },
    }));

    if (!value) {
      setSymbolFxError((prevError) => ({
        ...prevError,
        companyName: "This field is required",
      }));
      return;
    } else {
      setSymbolFxError((prevError) => ({
        ...prevError,
        companyName: "",
      }));
    }
  };

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);
    if (data.icon) {
      setImagePreview(
        data.icon ? `${url + "/static/currencyImage/" + data.icon}` : ""
      );
    }
    setSymbolFx({
      symbol: { value: data.symbol, required: true },
      currencyImage: { value: `${data.icon}`, required: true },
      companyName: { value: `${data.company_name}`, required: true },
    });
    setShow(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(symbolFx).map(([key, { value }]) => [key, value])
      );

      for (let key in symbolFx) {
        const validationError = ValidateInputFields(
          key,
          symbolFx[key].value,
          symbolFx[key].required
        );
        setSymbolFxError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      const formData =
        isEdit && createFormDataFromObject({ id: editData.id, ...values });

      const mutationResult = isEdit && (await updateFxCoin(formData));

      handleToast(mutationResult);
      if (mutationResult?.data?.status) {
        handleClose();
        refetch();
      }
    };

    handleUpdateOrAdd();
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">Fx Currency Pairs</h3>
            <div className=" ms-auto"></div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <CurrencyTable data={data} onEdit={handleEdit} />
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        isOpen={show}
        onClose={handleClose}
        title={"Edit Currency"}
        footer={
          <Button
            className="btn btn1"
            variant={"primary"}
            isLoading={updatingCrypto}
            onClick={handleSubmit}
          >
            {"Update"}
          </Button>
        }
      >
        <form>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="fw-bold">Symbol*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Symbol"
                name="symbol"
                value={symbolFx.symbol.value}
                disabled={isEdit}
              />
              <span style={{ color: "red" }}>{symbolFxError?.symbol}</span>
            </div>
            <div className="form-group col-md-6">
              <label className="fw-bold">Currency Image*</label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                name="currencyImage"
                // value={symbolFx.currencyImage?.value}
                onChange={handleImageChange}
              />
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Currency"
                  className="input_upload_img"
                />
              )}
              <span style={{ color: "red" }}>
                {symbolFxError?.currencyImage}
              </span>
            </div>
            <div className="form-group col-md-6">
              <label className="fw-bold">Company Name*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Company Name"
                name="company"
                value={symbolFx.companyName.value}
                onChange={handleChangeCompany}
              />
              <span style={{ color: "red" }}>{symbolFxError?.companyName}</span>
            </div>
          </div>
        </form>
      </CustomModal>
    </div>
  );
};

export default Currency;
