import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, Tooltip, IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { formatDate } from "../../utils/format";
import DeleteIcon from "@mui/icons-material/Delete";
import BigNumber from "bignumber.js";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const BuyOrdersTableMargin = ({ data, onDelete }) => {
  const formatNumberIso = (number) => {
    return number.toNumber();
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "S. No.",
        enableEditing: false,
        size: 40,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },

      {
        accessorKey: "email",
        header: "Email",
        Cell: ({ row: { original } }) => <span>{original.email || "-"}</span>,
      },
      {
        accessorKey: "mobile_number",
        header: "Mobile No.",
        Cell: ({ row: { original } }) => (
          <span>{original.mobile_number || "-"}</span>
        ),
      },
      {
        accessorKey: "avgPrice",
        header: "Entry Point",
      },
      {
        accessorKey: "totalQuantity",
        header: "Volume/Lots",
      },
      {
        accessorKey: "currentPrice",
        header: "Price Change",
      },

      {
        // accessorKey: "coin_pair",
        header: "Pair",
        Cell: ({ row: { original } }) => {
          //   console.log("row", original);
          return (
            <span>
              {original.firstCoin}/{original.secondCoin}
            </span>
          );
        },
      },

      {
        accessorKey: "take_profit",
        header: "Take Profit",
        Cell: ({ row: { original } }) => (
          <span>{original.take_profit || "-"}</span>
        ),
      },

      {
        accessorKey: "stop_price",
        header: "Stop Loss",
        Cell: ({ row: { original } }) => (
          <span>{original.stop_price || "-"}</span>
        ),
      },

      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ row: { original } }) => <span>{original.status}</span>,
      },

      {
        // accessorKey: "status",
        header: "P&L",
        Cell: ({ row: { original } }) => {
          const currentPricea = new BigNumber(original.currentPrice);
          const orderPrice = new BigNumber(original.avgPrice);
          let priceChange;
          if (original.orderSides.includes("BUY")) {
            priceChange = currentPricea.minus(orderPrice);
          } else {
            priceChange = orderPrice.minus(currentPricea);
          }

          const quantity = parseFloat(original.totalQuantity);
          const floatingProfit = priceChange.multipliedBy(quantity);

          return (
            <span
              style={
                floatingProfit < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              {isNaN(floatingProfit) ? "0.0" : formatNumberIso(floatingProfit)}
            </span>
          );
        },
      },

      {
        accessorKey: "created_at",
        header: "Date",
        enableEditing: false,
        Cell: ({ row }) => <span>{formatDate(row.original.created_at)}</span>,
      },
      {
        // accessorKey: "createdAt",
        header: "Action",
        enableEditing: false,
        Cell: ({ row }) =>
          row.original.status === "pending" ? (
            <>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={() =>
                    onDelete({
                      ...row.original,
                      orderType: "buy",
                      orderId: row.original.buy_id,
                    })
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            "-"
          ),
      },
    ],
    [data]
  );

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: data ? data : [],
    // enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableEditing: false,
    getRowId: (row) => row.id,

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};
