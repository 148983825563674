// UserList.jsx
import React, { useState } from "react";
import CustomModal from "../../widgets/Modal";
import { useAssignIBCoinsMutation } from "../../../redux/brokerApi";
import Button from "../../widgets/Button";
import TableLoader from "../../widgets/TableLoader";
import SubIBTabs from "./SubIbTabs";
import { handleToast } from "../../utils/handleToast";
import { useGetCryptosQuery } from "../../../redux/cryptoApi";
import { useGetSubIbsQuery } from "../../../redux/subIbApi";
import {
  UserPopupTabs,
  UsersTable,
  HeadWrapper,
  UserStatus,
  UserInfoTabs,
  UserDelete,
  TwoFA,
} from "../Elements";
import Profile from "./Profile";
import { useSelector } from "react-redux";
import PageLoader from "../../widgets/PageLoader";
import { AddBalanceForm } from "../Elements/AddBalance";

const SubIbList = () => {
  const {
    permissions: { ib },
  } = useSelector((state) => state.auth);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const { data: records, isLoading, refetch } = useGetSubIbsQuery();
  const [assignIBCoins, { isLoading: assigningIbCoins }] =
    useAssignIBCoinsMutation();
  const { data: cryptoCoins } = useGetCryptosQuery();
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [isStatus, setIsStatus] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isTwoFA, setIsTwoFA] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const [showTabs, setShowTabs] = useState(false);
  const [ibData, setIbData] = useState(null);
  const [selectedCoins, setSelectedCoins] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [userData, setUserData] = useState(null);
  const [isInfo, setIsInfo] = useState(false);
  const [isBalanceAdd, setIsBalanceAdd] = useState(false);
  const handlePermissions = (data) => {
    setShowTabs(true);
    setIbData(data);
  };

  const handleCoinChange = (selectedCoins) => {
    setSelectedCoins(selectedCoins);
  };

  const handleAddClick = () => {
    setShowAddPopup(true);
    setIsNew(true);
  };

  const handlePopupClose = () => {
    setShowAddPopup(false);
    setStatusData(null);
    setEditData(null);
    setIsEdit(false);
    setIsStatus(false);
    setIsNew(false);
    setTabIndex(0);
    setUserData(null);
    setIsInfo(false);
    setIsBalanceAdd(false);
  };

  const handleEdit = (data, tabIndex) => {
    setEditData(data);
    setIsEdit(true);
    setShowAddPopup(true);
    setIsNew(false);
    if (tabIndex) {
      setTabIndex(tabIndex);
    }
    refetch();
  };

  const handleUserInfo = (data) => {
    setUserData(data);
    setIsInfo(true);
    setShowAddPopup(true);
  };

  const addBalance = (data) => {
    setUserData(data);
    setIsBalanceAdd(true);
  };

  const handleStatus = (data) => {
    setStatusData(data);
    setIsStatus(true);
    setShowAddPopup(true);
  };

  const handleDelete = (data) => {
    setStatusData(data);
    setIsDelete(true);
    setShowAddPopup(true);
  };

  const handleTwoFA = (data) => {
    setStatusData(data);
    setIsTwoFA(true);
    setShowAddPopup(true);
  };

  const handlePermissionsTabsSubmit = async () => {
    const values = selectedCoins.map((coinId) => coinId.value);
    const result = await assignIBCoins({ ibId: ibData.id, coinIds: values });
    handleToast(result);
    if (result?.data?.status) {
      setShowTabs(false);
    }
  };

  if (!ib?.permissions?.subIb) {
    return <PageLoader />;
  }

  return (
    <HeadWrapper
      title="Customers"
      button={
        ib?.permissions?.subIb?.write && (
          <button
            // disabled={addingAdmin}
            className="btn btn1"
            onClick={handleAddClick}
          >
            Add Customers
          </button>
        )
      }
    >
      {isLoading ? (
        <TableLoader />
      ) : (
        <div className="white-box">
          <div className="table-responsive">
            <UsersTable
              data={records}
              onEdit={handleEdit}
              onStatus={handleStatus}
              onPermission={handlePermissions}
              access={ib?.permissions.subIb}
              handleUserInfo={handleUserInfo}
              addBalance={addBalance}
              onDelete={handleDelete}
              onTwoFactor={handleTwoFA}
            />
          </div>
        </div>
      )}

      {isBalanceAdd && (
        <CustomModal
          isOpen={isBalanceAdd}
          onClose={() => setIsBalanceAdd(false)}
          maxWidth="1000px"
          title="Add Balance"
        >
          <AddBalanceForm editData={userData} onClose={handlePopupClose} />
        </CustomModal>
      )}

      {isInfo && (
        <CustomModal
          isOpen={showAddPopup}
          onClose={handlePopupClose}
          maxWidth="1000px"
          title="User Portfolio"
        >
          <UserInfoTabs userId={userData.id} />
        </CustomModal>
      )}

      {isStatus && showAddPopup && (
        <UserStatus
          id={statusData.id}
          onClose={handlePopupClose}
          show={isStatus}
          refetch={refetch}
          status={statusData.status}
        />
      )}

      {isTwoFA && showAddPopup && (
        <TwoFA
          id={statusData.id}
          onClose={handlePopupClose}
          show={isTwoFA}
          refetch={refetch}
          status={statusData.enable_2fa}
        />
      )}

      {isDelete && showAddPopup && (
        <UserDelete
          id={statusData.id}
          onClose={handlePopupClose}
          show={isDelete}
          refetch={refetch}
          status={statusData.status}
        />
      )}

      {isNew && showAddPopup && (
        <CustomModal
          isOpen={showAddPopup}
          onClose={handlePopupClose}
          maxWidth="1000px"
          title="Add Customers"
        >
          <UserPopupTabs
            isNew={isNew}
            // editData={editData}
            onClose={handlePopupClose}
            refetch={refetch}
            getNewUser={handleEdit}
            Profile={Profile}
            tabIndex={tabIndex}
            handleTabIndex={setTabIndex}
          />
        </CustomModal>
      )}

      {isEdit && showAddPopup && (
        <CustomModal
          isOpen={showAddPopup}
          onClose={handlePopupClose}
          maxWidth="1000px"
          title="Edit Sub Ib"
        >
          <UserPopupTabs
            isEdit={isEdit}
            editData={editData}
            refetch={refetch}
            onClose={handlePopupClose}
            getNewUser={handleEdit}
            Profile={Profile}
            tabIndex={tabIndex}
            handleTabIndex={setTabIndex}
          />
        </CustomModal>
      )}

      {showTabs && (
        <CustomModal
          isOpen={showTabs}
          onClose={() => setShowTabs(false)}
          title={"Add Permissions"}
          footer={
            <Button
              className="btn btn1"
              variant={"primary"}
              isLoading={assigningIbCoins}
              onClick={handlePermissionsTabsSubmit}
            >
              Submit
            </Button>
          }
        >
          <SubIBTabs
            coins={cryptoCoins}
            onCoin={handleCoinChange}
            ibId={ibData?.id}
          />
        </CustomModal>
      )}
    </HeadWrapper>
  );
};

export default SubIbList;
