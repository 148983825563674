import React, { useEffect, useState } from "react";
import {
  useAddAdminMutation,
  useUpdateAdminMutation,
} from "../../../redux/adminApi";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import { handleToast } from "../../utils/handleToast";
import Button from "../../widgets/Button";
import PhoneInput from "react-phone-input-2";
import RoleTable2 from "../Elements/RoleTable2";
import RoleTable from "../Elements/RoleTable";
import { useSelector } from "react-redux";
import cryptoManagementArray from "../Elements/permission";
import { useGetPermissionsQuery } from "../../../redux/usersApi";
import TableLoader from "../../widgets/TableLoader";

const Profile = ({ isEdit, data, isNew, refetch, onClose, getNewUser }) => {
  const { user } = useSelector((state) => state.auth);
  const [addAdmin, { isLoading: addingAdmin }] = useAddAdminMutation();
  const [updateAdmin, { isLoading: updatingAdmin }] = useUpdateAdminMutation();
  const [userPermissions, setUserPermissions] = useState(cryptoManagementArray);
  const {
    data: permissions,
    isLoading,
    isSuccess,
    refetch: refetchPermissions,
  } = useGetPermissionsQuery(
    {
      userId:
        isEdit || user?.role === "Global Admin"
          ? data?.id
          : user?.role === "Admin"
          ? user.id
          : data?.id,
      userAddType: "admin",
    },
    { skip: isNew }
  );

  const [editData, setEditData] = useState();
  const [admin, setAdmin] = useState({
    name: { value: "", required: true },
    email: { value: "", required: true },
    mobile_number: { value: "", required: true },
    password: { value: "", required: true },
    permissions: {
      value: [],
      required: false,
    },
  });
  const [adminError, setAdminError] = useState({
    name: "",
    email: "",
    mobile_number: "",
    password: "",
    permission: "",
  });

  useEffect(() => {
    if (data && isSuccess) {
      setUserPermissions(permissions);
      handleEdit(data);
    }
  }, [data, isSuccess, permissions]);

  const handleEdit = (data) => {
    setEditData(data);
    setAdmin({
      name: { value: data.name, required: true },
      email: { value: data.email, required: true },
      mobile_number: { value: `${data.mobile_number}`, required: true },
      password: { value: "", required: false },
      permissions: {
        value: permissions,
        required: false,
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdmin((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(
      name,
      value,
      admin[name].required
    );

    setAdminError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(admin).map(([key, { value }]) => [key, value])
      );

      for (let key in admin) {
        const validationError = ValidateInputFields(
          key,
          admin[key].value,
          admin[key].required
        );
        setAdminError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      // return;
      const isAdminPermissionValid = values.permissions
        .flatMap(({ permissions }) => permissions.flatMap(Object.values))
        .some((val) => !isNaN(val) && val === "1");

      if (!isAdminPermissionValid) {
        handleToast({
          data: { status: false, message: "At least one permission needed" },
        });
        return;
      }

      // return;
      const mutationResult = isEdit
        ? await updateAdmin({ id: editData.id, ...values })
        : await addAdmin(values);

      handleToast(mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        if (!isNew) {
          refetchPermissions();
        }
        const tabIndex = isNew ? 1 : 0;
        getNewUser(
          {
            ...mutationResult.data.data,
            id: mutationResult.data.data.id,
          },
          tabIndex
        );

        // onClose();
      }
    };

    handleUpdateOrAdd();
  };

  const getPermissions = (permission) => {
    setAdmin((prev) => ({
      ...prev,
      permissions: { ...prev.permissions, value: permission, required: false },
    }));
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="fw-bold">Name*</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name"
              name="name"
              onChange={handleChange}
              value={admin.name.value}
            />
            <span style={{ color: "red" }}>{adminError?.name}</span>
          </div>
          {isNew && (
            <div className="form-group col-md-6">
              <label className="fw-bold">Email*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email"
                name="email"
                onChange={handleChange}
                value={admin.email.value}
                autoComplete="off"
              />
              <span style={{ color: "red" }}>{adminError?.email}</span>
            </div>
          )}
          <div className="form-group col-md-6">
            <label className="fw-bold">Phone Number*</label>
            {/* <input
            type="tel"
            className="form-control"
            placeholder="Enter Phone Number"
            name="mobile_number"
            onChange={handleChange}
            value={admin.mobile_number.value}
          /> */}
            <PhoneInput
              key={"phoneInput"}
              country={"us"}
              value={admin.mobile_number.value}
              onChange={(phone) => {
                setAdmin((prev) => {
                  return {
                    ...prev,
                    mobile_number: { ...prev["mobile_number"], value: phone },
                  };
                });
                const validationError = ValidateInputFields(
                  "mobile_number",
                  phone,
                  admin["mobile_number"].required
                );
                setAdminError((prevError) => {
                  return { ...prevError, mobile_number: validationError };
                });
              }}
              className=""
              placeholder="Email/Mobile"
              enableSearch={true}
              containerClass="baseclass"
              inputClass="somebaseclass"
              countryCodeEditable={false}
              name="mobile_number"
              inputProps={{
                autoFocus: true,
              }}
              // disabled={disableGetCode}
            />
            <span style={{ color: "red" }}>{adminError?.mobile_number}</span>
          </div>
          {isNew && (
            <div className="form-group col-md-6">
              <label className="fw-bold">Password* </label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Password"
                name="password"
                onChange={handleChange}
                value={admin.password.value}
                autoComplete="off"
              />
              <span style={{ color: "red" }}>{adminError?.password}</span>
            </div>
          )}
        </div>

        {isLoading && !isSuccess ? (
          <TableLoader />
        ) : isEdit ? (
          <RoleTable
            userId={data?.id}
            getPermissions={getPermissions}
            permissions={userPermissions}
            userAddType="admin"
          />
        ) : user?.role === "Global Admin" ? (
          <RoleTable
            userId={data?.id}
            getPermissions={getPermissions}
            userAddType="admin"
            permissions={userPermissions}
          />
        ) : user?.role === "Admin" ? (
          <RoleTable
            userId={user.id}
            getPermissions={getPermissions}
            userAddType="admin"
            permissions={userPermissions}
          />
        ) : (
          ""
        )}
        <Button
          className="btn btn1"
          variant={"primary"}
          isLoading={isEdit ? updatingAdmin : addingAdmin}
          onClick={handleSubmit}
        >
          {isEdit ? "Update" : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default Profile;
