import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { useGetCryptoPairsQuery } from "../../../redux/cryptoPairsApi";
import TableLoader from "../../widgets/TableLoader";
import toast from "react-hot-toast";

import {
  useAssetPermissionMutation,
  useGetAssetPairsQuery,
} from "../../../redux/usersApi";
import { useGetCommissionQuery } from "../../../redux/commissionApi";

export const Crypto = ({ editData, selectChange }) => {
  const [selectedPairs, setSelectedPairs] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [currantPairs, setCurrantPairs] = useState([]);
  const {
    data: pairsData,
    isLoading: pairsLoading,
    refetch: refetchPairs,
  } = useGetAssetPairsQuery({ userId: editData.id, type: "crypto" });
  const { data: records, isLoading } = useGetCryptoPairsQuery();
  const [updateAssetPermissions, { isLoading: updatingAssets }] =
    useAssetPermissionMutation();

  const {
    data: userCommissions = [],
    isLoading: commissionLoading,
    refetch: refetchUserCommissions,
    isFetching,
  } = useGetCommissionQuery({
    userId: editData.refred_by_id,
  });

  console.log("editData.refred_by_id",editData.refred_by_id)

  console.log(records, "RRRR", userCommissions, "RWFERPERSMIN");

  useEffect(() => {
    const createKeyValuePair = (pairs) => {
      return pairs?.map((pair) => ({
        value: pair?.pair_id,
        label: `${pair.name1?.toUpperCase()}/${pair.name2?.toUpperCase()}`,
      }));
    };
    if (pairsData && editData) {
      setCurrantPairs(pairsData);
      const pairValues = createKeyValuePair(pairsData);
      selectChange({
        selectedPairs: pairValues,
        type: "crypto",
        fn: refetchPairs,
      });
      setSelectedPairs(pairValues);
      setIsShow(true);
    }
  }, [editData, pairsData]);

  const options = useMemo(() => {
    return userCommissions?.map((cryptoPair) => ({
      value: cryptoPair.pairId,
      label: `${cryptoPair.first_coin_symbol}/${cryptoPair.second_coin_symbol}`,
    }));
  }, [userCommissions]);

  const selectedOptions = useMemo(() => {
    return currantPairs?.map((pair) => ({
      value: pair.pair_id,
      label: `${pair.name1?.toUpperCase()}/${pair.name2?.toUpperCase()}`,
    }));
  }, [currantPairs]);

  if (!pairsData && !records) {
    return <TableLoader />;
  }
  return (
    <>
      {isLoading && pairsLoading ? (
        <TableLoader />
      ) : (
        <div>
          <h4>Select Crypto Pairs</h4>
          {!isShow ? (
            <TableLoader />
          ) : (
            <Select
              defaultValue={selectedOptions}
              isMulti
              name="colors"
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              isLoading={isLoading}
              onChange={(selected) => {
                setSelectedPairs(selected);
                selectChange({
                  selectedPairs: selected,
                  type: "crypto",
                  fn: refetchPairs,
                });
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
