import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { formatDate } from "../../utils/format";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import BigNumber from "bignumber.js";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const SellOrdersTableFx = ({ data, onDelete }) => {
  const formatNumberIso = (number) => {
    return number.toNumber();
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "S. No.",
        enableEditing: false,
        size: 40,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      // {
      //   accessorKey: "user_name",
      //   header: "Username",
      //   size: 50,
      // },
      {
        accessorKey: "email",
        header: "Email",
        Cell: ({ row: { original } }) => <span>{original.email || "-"}</span>,
      },
      {
        accessorKey: "mobile_number",
        header: "Mobile No.",
        Cell: ({ row: { original } }) => (
          <span>{original.mobile_number || "-"}</span>
        ),
      },
      {
        accessorKey: "symbol",
        header: "Symbol",
        // Cell: ({ row: { original } }) => (
        //   <span>{`${original.first_coin_symbol}/${original.second_coin_symbol}`}</span>
        // ),
      },
      {
        accessorKey: "quantity",
        header: "Volume",
      },
      {
        accessorKey: "price",
        header: "price",
        size: 50,
      },
      // {
      //   accessorKey: "updated_at",
      //   header: "Quantity",
      //   Cell: ({ row }) => <span>{formatDate(row.original.updated_at)}</span>,
      // },
      // {
      //   // accessorKey: "refer_id",
      //   header: "Price Change",
      //   Cell: ({ row }) => (
      //     <>
      //       {Number(row.original.price)}
      //       <ArrowRightAltIcon />
      //       {Number(row.original.closed_price)}{" "}
      //     </>
      //   ),
      // },

      // {
      //   // accessorKey: "refer_id",
      //   header: "Profit and loss",
      //   Cell: ({ row }) => <>{Number(row.original.price)}</>,
      // },

      // {
      //   accessorKey: "buy_fees",
      //   header: "Buy Fees",
      // },

      // {
      //   accessorKey: "orderMethod",
      //   header: "Order Type",
      // },
      {
        // accessorKey: "p&l",
        header: "P&L",
        Cell: ({ row: { original } }) => {
          const currentPricea =
            original.order_side === "BUY"
              ? new BigNumber(original.buyPrice)
              : new BigNumber(original.sellPrice);
          const orderPrice = new BigNumber(original.price);
          const quantity = parseFloat(original.quantity) * parseFloat(100000);
          let priceChange;
          if (original.order_side === "BUY") {
            priceChange = currentPricea.minus(orderPrice);
          } else if (original.order_side === "SELL") {
            priceChange = orderPrice.minus(currentPricea);
          }
          const flotingProfit = priceChange.multipliedBy(quantity);
          return (
            <span
              style={
                flotingProfit < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              {isNaN(flotingProfit) ? "0.0" : formatNumberIso(flotingProfit)}
            </span>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ row: { original } }) => <span>{original.status}</span>,
      },

      {
        accessorKey: "createdAt",
        header: "Date",
        enableEditing: false,
        Cell: ({ row }) => <span>{formatDate(row.original.updated_at)}</span>,
      },
      {
        // accessorKey: "createdAt",
        header: "Action",
        enableEditing: false,
        Cell: ({ row }) =>
          row.original.status === "pending" ? (
            <>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={() =>
                    onDelete({
                      ...row.original,
                      orderType: "sell",
                      status: "cancled",
                      orderId: row.original.id,
                    })
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Close">
              <IconButton
                color="success"
                onClick={() =>
                  onDelete({
                    ...row.original,
                    orderType: "sell",
                    status: "close",
                    orderId: row.original.id,
                  })
                }
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ),
      },
    ],
    [data]
  );

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: data ? data : [],
    // enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableEditing: false,
    getRowId: (row) => row.id,

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};
