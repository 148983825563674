import React, { useState } from "react";
import {
  Box,
  InputLabel,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { HeadWrapper } from "../Elements";
import Button from "../../widgets/Button";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import { changePassword } from "../../services/Login";
import { handleToast } from "../../utils/handleToast";
import { logout } from "../../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState({
    currantPassword: { value: "", required: true },
    newPassword: { value: "", required: true },
    confirmNewPassword: { value: "", required: true },
  });

  const [passwordInfoError, setPasswordInfoError] = useState({
    currantPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  if (user.role !== "Broker") {
    return navigate("/dashboard");
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordInfo((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(
      name,
      value,
      passwordInfo[name].required
    );

    setPasswordInfoError((prevError) => {
      return { ...prevError, [name]: validationError };
    });

    if (name === "confirmNewPassword") {
      const checkMatchPassword = value === passwordInfo.newPassword.value;

      setPasswordInfoError((prevError) => {
        return {
          ...prevError,
          confirmNewPassword: checkMatchPassword
            ? ""
            : value
            ? "Please make sure your passwords match"
            : validationError,
        };
      });

      return;
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdate = async () => {
      const values = Object.fromEntries(
        Object.entries(passwordInfo).map(([key, { value }]) => [key, value])
      );

      for (let key in passwordInfo) {
        const validationError = ValidateInputFields(
          key,
          passwordInfo[key].value,
          passwordInfo[key].required
        );
        setPasswordInfoError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      if (!(values.newPassword === values.confirmNewPassword)) {
        setPasswordInfoError((prevError) => {
          return {
            ...prevError,
            confirmNewPassword: "Please make sure your passwords match",
          };
        });
        return;
      }

      const res = await changePassword(values);

      handleToast({ data: res });
      if (res.status) {
        setTimeout(() => {
          dispatch(logout());
        }, 2000);
      }
    };

    handleUpdate();
  };

  return (
    <HeadWrapper title="Change Password" button={null}>
      <Box
         className="white-box"
        maxWidth="xl"
        noValidate
        autoComplete="off"
      >
        <form>
          <FormControl
            sx={{ my: 1, width: "50ch" }}
            variant="outlined"
            fullWidth
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Current Password*
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              value={passwordInfo.currantPassword.value}
              name="currantPassword"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Current Password"
            />
            <span style={{ color: "red" }}>
              {passwordInfoError?.currantPassword}
            </span>
          </FormControl>
          <div>
            <FormControl
              sx={{ my: 1, width: "50ch" }}
              variant="outlined"
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-password">
                New Password*
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                onChange={handleChange}
                value={passwordInfo.newPassword.value}
                name="newPassword"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
              <span style={{ color: "red" }}>
                {passwordInfoError?.newPassword}
              </span>
            </FormControl>
            <FormControl
              sx={{ m: 1, width: "50ch" }}
              variant="outlined"
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm New Password*
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                onChange={handleChange}
                value={passwordInfo.confirmNewPassword.value}
                name="confirmNewPassword"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm New Password"
              />
              <span style={{ color: "red" }}>
                {passwordInfoError?.confirmNewPassword}
              </span>
            </FormControl>
          </div>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Button
              onClick={handleSubmit}
              variant="primary"
              className="btn btn1"
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Box>
    </HeadWrapper>
  );
};

export default ChangePassword;
